//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import utc from 'dayjs/plugin/utc';
import YocketProductsRedirectionsDropdown from '../YocketProductsRedirectionsDropdown.vue';
import NavItem from './NavItem.vue';
import Dropdown from '~/components/ReusableComponents/Dropdown';
import ProfilePic from '~/components/Profile/ProfilePic.vue';
import analyticEvents, { ANALYTIC_SOURCE_MODULES } from '~/services/analyticEvents';
import {
    OTHER_LINKS,
    DASHBOARD_REDIRECTION,
    LIVE_TEST_TIMINGS,
    TEST_TYPES,
    PLATFORM_FEATURES
} from '~/static/constants.js';
import { UTM_SOURCES } from '~/constants/yocketConstants';
import { phone, expertIconGrey } from '~/static/icons/index.js';
import DashboardSelector from '~/components/Navbar/Web/DashboardSelector.vue';
import PrepToolsSelector from '~/components/Navbar/Web/PrepToolsSelector.vue';
import PrepCourseSelector from '~/components/Navbar/Web/PrepCourseSelector.vue';
import ResourceSelector from '~/components/Navbar/Web/ResourceSelector.vue';
import { ROUTES } from '~/constants/pageRoutes';
// import YocketRedirections from '~/components/Navbar/Web/YocketRedirections.vue';

// importing icons
import {
    avatar,
    chevronGrayBold,
    logoutIcon,
    profileIcon,
    support,
    wrappedGiftYellow,
    wrappedGiftPurple,
    recordingSymbol,
    yocketText,
    yocketLogo
} from '~/static/icons/index';
import { isFeatureAllowed } from '~/services/utilityFunctions';
import { EXAM_TYPES } from '~/constants/generalConstants';
import Chevron from '~/assets/Icons/Chevron.vue';

dayjs.extend(isBetween);
dayjs.extend(utc);

const NudgeTag = () => import('~/components/ReusableComponents/NudgeTag.vue');
const ModalContainer = () => import('~/components/ReusableComponents/ModalContainer.vue');
const AuthModal = () => import('~/components/Auth/AuthModal.vue');
const InstructionsModal = () => import('~/components/Home/InstructionsModal.vue');
const DownloadAppNudge = () => import('~/components/DownloadApp/DownloadAppNavbarNudge.vue');
export default {
    name: 'NavbarWeb',
    components: {
        Dropdown,
        ProfilePic,
        DashboardSelector,
        PrepToolsSelector,
        PrepCourseSelector,
        ResourceSelector,
        // YocketRedirections,
        NudgeTag,
        ModalContainer,
        AuthModal,
        InstructionsModal,
        NavItem,
        DownloadAppNudge,
        Chevron,
        YocketProductsRedirectionsDropdown
    },
    data() {
        return {
            // icons
            avatar,
            chevronGrayBold,
            profileIcon,
            support,
            wrappedGiftYellow,
            wrappedGiftPurple,
            recordingSymbol,
            yocketLogo,
            yocketText,

            // CONSTANTS
            OTHER_LINKS,
            UTM_SOURCES,
            DASHBOARD_REDIRECTION,
            LIVE_TEST_TIMINGS,
            ROUTES,
            PLATFORM_FEATURES,
            ANALYTIC_SOURCE_MODULES,

            // data
            dropDownData: [
                [
                    {
                        id: 2,
                        action: 'profile_details',
                        title: 'Profile Details',
                        img: profileIcon
                    },
                    {
                        id: 3,
                        action: 'customer_support',
                        title: 'Help and Support',
                        img: support
                    }
                ],
                [
                    {
                        id: 4,
                        action: 'logout',
                        title: 'Logout',
                        img: logoutIcon
                    }
                ]
            ],

            showDropdown: false,
            showYocketProductsDropdown: false,
            phone,
            updateExpiryInterval: null,
            isLoginModalOpen: false,
            greLiveTestData: null,
            fetchingTest: false,
            expertIconGrey,
            windowWidth: 0
        };
    },
    computed: {
        ...mapGetters({
            user: 'auth/getUser',
            isAuthenticated: 'auth/isAuthenticated',
            isDashboardSetup: 'auth/isDashboardSetup',
            goToSelectDate: 'priorityPlanner/goToSelectDate',
            isPlannerSetup: 'auth/isPlannerSetup',
            isPremiumUser: 'auth/isPremiumUser',
            userCountry: 'auth/userCountry',
            showYocketBanner: 'auth/showYocketBanner',
            freeTrialStatus: 'auth/freeTrialStatus',
            getUserPremiumFeatures: 'auth/getUserPremiumFeatures',
            featurePermissions: 'auth/featurePermissions',
            liveClassAccess: 'auth/liveClassAccess',
            isLiveClassUpgradeAvailable: 'auth/isLiveClassUpgradeAvailable',
            liveTestTaken: 'auth/liveTestTaken',
            showInstructions: 'testSets/showInstructions',
            isLearningAidAccessible: 'auth/isLearningAidAccessible',
            hasPrepFreebies: 'auth/hasPrepFreebies',
            isOnDemandTrialActive: 'auth/isOnDemandTrialActive',
            isPlanExpired: 'auth/isPlanExpired'
        }),
        // Don't show gre live test button in these routes
        restrictedRoutesForGreLiveTest() {
            return ![ROUTES.GRE_QUICK_DIAGNOSTIC].includes(this.$route.path);
        },
        paymentRedirection() {
            return process.env.NORMAL_LIVE_CLASSES_PAYMENT_LINK;
        },
        freeTrialLabel() {
            if (this.isPlanExpired) {
                return 'Plan expired, Upgrade Now';
            }
            // A shorter upgrade tag label to accomodate the 'GRE Live' tab
            if (this.showGreLiveTestTouchpoint) {
                return 'Upgrade Now';
            }

            if (this.freeTrialStatus.expired) {
                if (this.windowWidth < 1100) {
                    return 'Upgrade Now';
                } else {
                    return 'Free trial ended, Upgrade Now';
                }
            } else {
                const remainingTime = this.freeTrialStatus.remainingTime;
                if (!remainingTime) return '__';

                const validity =
                    remainingTime.days > 0
                        ? remainingTime.days + 'd ' + remainingTime.hours + 'h '
                        : remainingTime.hours + 'h ' + remainingTime.minutes + 'm ';

                if (this.windowWidth < 1100) {
                    return 'Upgrade Now';
                } else {
                    return `${validity} of Trial Left, Upgrade Now`;
                }
            }
        },
        showGreLiveTestTouchpoint() {
            // Enabling live test for all users, including international
            // if (this.user && this.user.country_code !== 91) {
            //     return false;
            // }

            return (
                new Date().getTime() >= LIVE_TEST_TIMINGS.LIVE_TEST_VISIBILITY &&
                new Date().getTime() < LIVE_TEST_TIMINGS.END_TIME &&
                // this.userCountry === 'IN' &&
                !this.liveTestTaken &&
                this.restrictedRoutesForGreLiveTest
            );
        },
        isLiveTestActive() {
            return (
                new Date().getTime() >= LIVE_TEST_TIMINGS.START_TIME &&
                new Date().getTime() < LIVE_TEST_TIMINGS.END_TIME
            );
        },
        toggleLiveDemo() {
            const showLiveDemo = process.env.SHOW_LIVE_DEMO;
            const liveDemoBetween = process.env.TOGGLE_LIVE_DEMO_BETWEEN;

            const now = dayjs.utc();
            let toggleLiveDemo = false;
            // weekends => toggleLiveDemo = false
            // 0 - sunday, 6 - saturday
            const isWeekEnd = [0, 6].includes(now.get('day'));

            //
            if (showLiveDemo === 'true' && Boolean(liveDemoBetween) && !isWeekEnd) {
                const liveDemoHours = liveDemoBetween.split(',');
                const startHour = liveDemoHours[0].split(':');
                const endHour = liveDemoHours[1].split(':');

                const liveDemoStart = dayjs
                    .utc()
                    .startOf('h')
                    .set('h', parseInt(startHour[0] ?? '16'))
                    .set('m', parseInt(startHour[1] ?? '0'));

                const liveDemoEnd = dayjs
                    .utc()
                    .endOf('h')
                    .set('h', parseInt(endHour[0] ?? '18'))
                    .set('m', parseInt(endHour[1] ?? '0'));

                if (now.isBetween(liveDemoStart, liveDemoEnd)) toggleLiveDemo = true;
            }

            return toggleLiveDemo;
        },
        isLearningDashboardAccessible() {
            return this.isLearningAidAccessible || this.isOnDemandTrialActive;
        }
    },
    mounted() {
        this.windowWidth = window.innerWidth;
        window.addEventListener('resize', this.onWidthChange);

        if (
            this.freeTrialStatus.activated &&
            !this.freeTrialStatus.expired &&
            isFeatureAllowed(PLATFORM_FEATURES.FREE_TRIAL, this.featurePermissions)
        ) {
            /**
             * more than 1 day => 10 mins
             * 1 hour to 1 day => 2 min
             * less than 1 hour => 10sec
             */
            const intervalTime =
                this.freeTrialStatus.remainingTime.days > 0
                    ? 10 * 60 * 1000
                    : this.freeTrialStatus.remainingTime.hours > 1
                    ? 2 * 60 * 1000
                    : 10 * 1000;

            this.updateExpiryInterval = setInterval(() => {
                if (this.freeTrialStatus.expired) {
                    clearInterval(this.updateExpiryInterval);
                    return;
                }
                this.$store.commit('auth/setFreeTrialData');
            }, intervalTime);
        }
    },
    destroyed() {
        window.removeEventListener('resize', this.onWidthChange);
        clearInterval(this.updateExpiryInterval);
    },
    methods: {
        isFeatureAllowed,
        toggleYocketProductsDropdown() {
            this.showYocketProductsDropdown = !this.showYocketProductsDropdown;
        },
        onWidthChange() {
            this.windowWidth = window.innerWidth;
        },
        selectOption(option) {
            switch (option.action) {
                case 'logout': {
                    this.$store.dispatch('auth/logout');
                    this.$store.commit('priorityPlanner/resetState');
                    this.$store.commit('dashboard/resetDashboardMode');
                    break;
                }
                case 'profile_details': {
                    this.$router.push(ROUTES.PROFILE);
                    break;
                }
                case 'customer_support': {
                    window.open(OTHER_LINKS.NEW_SUPPORT_TICKET, '_blank');
                    break;
                }
                default:
            }
            this.showDropdown = false;
        },
        isRouteActive(mainRoute) {
            return this.$route.path.includes(mainRoute);
        },
        handleProfileImageClick() {
            if (this.$device.isMobile) this.$router.push(ROUTES.PROFILE);
            else this.showDropdown = !this.showDropdown;
        },
        async launchLiveTest() {
            // 1. Check for auth
            if (!this.isAuthenticated) {
                this.isLoginModalOpen = true;
                return;
            }

            this.isLoginModalOpen = false;

            if (this.liveTestTaken) {
                location.reload();
                return;
            }

            if (this.fetchingTest) {
                return;
            }

            // 2. Fetch test
            const queryParams = {
                test_type_keys: `${TEST_TYPES.GRE_LIVE_TEST}`
            };

            if (!this.greLiveTestData) {
                this.fetchingTest = true;
                const { data } = await this.$api.testSets.getTestSets(EXAM_TYPES.GRE, queryParams);
                this.fetchingTest = false;
                this.greLiveTestData = data.data[0];
            }

            // 3. Launch instructions modal
            this.$store.commit('testSets/setActiveTest', this.greLiveTestData);
            this.$store.commit('testSets/setShowInstructions', true);
        },
        fireGAEvent(eventFor, payload = null) {
            switch (eventFor) {
                case 'login':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.login_click,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR
                        }
                    });
                    break;

                case 'practiceDashboard':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.nav_practice_switched,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR
                        }
                    });
                    break;

                case 'learningDashboard':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.nav_learning_switched,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR
                        }
                    });
                    break;

                case 'planner':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.study_planner_click,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR
                        }
                    });
                    break;

                case 'vocabConnect':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.vocab_builder,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR
                        }
                    });
                    break;

                case 'prepPlus':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.pricing,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR
                        }
                    });
                    break;

                case 'liveClass':
                    {
                        const event = this.isLiveClassUpgradeAvailable
                            ? analyticEvents.upgrade_classes_clicked
                            : analyticEvents.prep_classes_nav_click;

                        this.$store.dispatch('analytics/trackEvent', {
                            event,
                            data: {
                                source: ANALYTIC_SOURCE_MODULES.NAVBAR
                            }
                        });
                    }
                    break;

                case 'onDemand':
                    {
                        const event = analyticEvents.prep_ondemand_nav_click;

                        this.$store.dispatch('analytics/trackEvent', {
                            event,
                            data: {
                                source: ANALYTIC_SOURCE_MODULES.NAVBAR
                            }
                        });
                    }
                    break;

                case 'greGuide':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.nav_greguide_clicked,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR,
                            tab_name: payload ?? undefined
                        }
                    });
                    break;
                case 'gmatGuide':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.gmat_guide_clicked,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR,
                            tab_name: payload ?? undefined
                        }
                    });
                    break;

                case 'greBlogs':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.nav_greblogs_clicked,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR
                        }
                    });
                    break;
                case 'gmatBlogs':
                    this.$store.dispatch('analytics/trackEvent', {
                        event: analyticEvents.nav_gmatblogs_clicked,
                        data: {
                            source: ANALYTIC_SOURCE_MODULES.NAVBAR
                        }
                    });
                    break;

                case 'greConsultation':
                    {
                        const event = analyticEvents.prep_consultation_navbar;

                        this.$store.dispatch('analytics/trackEvent', {
                            event,
                            data: {
                                source: ANALYTIC_SOURCE_MODULES.NAVBAR
                            }
                        });
                    }
                    break;

                case 'bookDemo':
                    {
                        const event = analyticEvents.enquire_now;

                        this.$store.dispatch('analytics/trackEvent', {
                            event,
                            data: {
                                source: ANALYTIC_SOURCE_MODULES.NAVBAR
                            }
                        });
                    }
                    break;

                case 'liveDemo':
                    {
                        const event = analyticEvents.prep_live_demo_navbar;

                        this.$store.dispatch('analytics/trackEvent', {
                            event,
                            data: {
                                source: ANALYTIC_SOURCE_MODULES.NAVBAR
                            }
                        });
                    }
                    break;
                case 'home':
                    {
                        const event = analyticEvents.home_click;

                        this.$store.dispatch('analytics/trackEvent', {
                            event,
                            data: {
                                source: ANALYTIC_SOURCE_MODULES.NAVBAR
                            }
                        });
                    }
                    break;

                default:
                    break;
            }
        }
    }
};
