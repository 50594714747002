export default (http, resource, apiVersion) => ({
    getPlannerData(data = null) {
        const method = 'post';
        const url = `${resource}/${apiVersion}/get-planner`;
        return http({ method, url, data });
    },

    /**
     * @param {test_date: timestamp, focus_subject: number | undefined} data
     * @returns Object
     */
    getPlannerDataV2(testType, data = null) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}`;
        return http({ method, url, data });
    },
    /**
     * @param {task_id: Number, task_type: String, is_completed: Boolean} data
     */
    updateTaskStatus(testType, data = null) {
        const method = 'put';
        const url = `${apiVersion}/${resource}/${testType}/task-status`;
        return http({ method, url, data });
    }
});
