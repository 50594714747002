export default (http, resource, apiVersion) => ({
    getArticle(testType, slug) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/${slug}`;
        return http({ method, url });
    },
    getBlogListing(testType, page) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}?page=${page}`;
        return http({ method, url });
    }
});
