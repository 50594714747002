export default (http, resource, apiVersion) => ({
    getAllPremiumPlans(testType, { couponCode, planSlug }) {
        const method = 'get';
        let url = `${apiVersion}/${resource}/${testType}/plans/`;
        if (couponCode && planSlug) {
            url = `${apiVersion}/${resource}/${testType}/plans?plan_key=${planSlug}&coupon_code=${couponCode}`;
        }

        return http({ method, url });
    },
    getSinglePlanDetails(testType, planSlug) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/plans/${planSlug}`;
        return http({ method, url });
    },
    validateCoupon(testType, data) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}/validate-coupon`;
        return http({ method, url, data });
    },
    createPremiumOrder(testType, data) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}/payment-order`;
        return http({ method, url, data });
    },
    confirmOrderSuccess(testType, data) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}/confirm-order-status`;
        return http({ method, url, data });
    },
    getSubscriptionOrderDetails(testType) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/subscription`;
        return http({ method, url });
    },
    createFreePremiumOrder(testType, data) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}/free-prep-plus-order`;
        return http({ method, url, data });
    },
    getUserReferralDetails(testType) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/referral`;
        return http({ method, url });
    },
    continueExpiredSubscription(testType) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}/subscription/continue-post-expiry`;
        return http({ method, url });
    }
});
