export default (http, resource, apiVersion) => ({
    submitAnswer(testType, testTakerId, questionId, data) {
        const method = 'put';
        const url = `${apiVersion}/${resource}/${testType}/evaluate/${testTakerId}/${questionId}`;
        return http({ method, url, data });
    },
    pauseAndSaveTest(testType, testTakerId, testSectionInstanceId, data = null) {
        const method = 'put';
        const url = `${apiVersion}/${resource}/${testType}/exit/save/${testTakerId}/${testSectionInstanceId}`;
        return http({ method, url, data });
    },
    exitTest(testType, testTakerId, testSectionInstanceId) {
        const method = 'put';
        const url = `${apiVersion}/${resource}/${testType}/exit/${testTakerId}/${testSectionInstanceId}`;
        return http({ method, url });
    },
    exitSectionOnTimerExpiry(testType, testTakerId, testSectionInstanceId) {
        const method = 'put';
        const url = `${apiVersion}/${resource}/${testType}/exit/timer-expiry/${testTakerId}/${testSectionInstanceId}`;
        return http({ method, url });
    }
});
