export default (http, resource, apiVersion) => ({
    getFeedbackConfig(testType) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}`;
        return http({ method, url });
    },
    submitFeedback(testType, testTakerId, questionId, data) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}/${testTakerId}/${questionId}`;
        return http({ method, url, data });
    }
});
