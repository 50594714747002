export default (http, resource, apiVersion) => ({
    getTestSets(testType, queryParams) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}`;
        return http({ method, url, params: queryParams });
    },
    getTestSetUsingId(testType, id) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/${id}`;
        return http({ method, url });
    }
});
