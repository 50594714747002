export default (http, resource, apiVersion) => ({
    getDashboard(testType) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/dashboard`;
        return http({ method, url });
    },
    startPracticeSet(testType, practiceSetId) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/practice-set/start?practice_set_id=${practiceSetId}`;
        return http({ method, url });
    },
    fetchQuizQuestionSet(testType, { questionSetId, practiceSessionId }) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/questions/${questionSetId}?test_taker_id=${practiceSessionId}`;
        return http({ method, url });
    },
    submitAnswer(testType, { practiceSessionId, questionId, data }) {
        const method = 'put';
        const url = `${apiVersion}/${resource}/${testType}/${practiceSessionId}/evaluate/${questionId}`;
        return http({ method, url, data });
    },
    submitSection(testType, practiceSessionId, data) {
        const method = 'put';
        const url = `${apiVersion}/${resource}/${testType}/${practiceSessionId}/submit-section`;
        return http({ method, url, data });
    },
    exitPracticeSet(testType, practiceSessionId) {
        const method = 'put';
        const url = `${apiVersion}/${resource}/${testType}/${practiceSessionId}/exit`;
        return http({ method, url });
    },
    getReport(testType, practiceSessionId) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}/${practiceSessionId}/report`;
        return http({ method, url });
    },
    getReviewWordList(testType, confidenceLevel) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/words-review/${confidenceLevel}`;
        return http({ method, url });
    },
    getSingleWordDetail(testType, wordIds) {
        let commaSeparatedIds = wordIds;
        if (Array.isArray(wordIds)) {
            commaSeparatedIds = wordIds.join(',');
        }

        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/words?word_ids=${commaSeparatedIds}`;
        return http({ method, url });
    }
});
