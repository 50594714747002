//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { PLATFORM_FEATURES, GMAT_TOPICS, GRE_TOPICS } from '~/static/constants.js';
import {
    greGuideIcon,
    gmatGuideIcon,
    greBlogIcon,
    gmatBlogIcon,
    freebiesIcon
} from '~/static/icons/index';
import { ROUTES } from '~/constants/pageRoutes';
import { GRE_GUIDES_DATA } from '~/static/guides/greGuidesData';
import { GMAT_GUIDES_DATA } from '~/static/guides/gmatGuidesData';
import { isFeatureAllowed } from '~/services/utilityFunctions';

const RESOURCE_TYPE = {
    FREEBIES: 1,
    GRE_GUIDE: 2,
    GMAT_GUIDE: 3,
    GRE_BLOG: 4,
    GMAT_BLOG: 5
};

export default {
    name: 'ResourceSelector',
    props: {
        windowWidth: { type: Number, default: 0 }
    },
    data() {
        return {
            // Data
            RESOURCE_TYPE,
            guideTopics: GRE_TOPICS,
            maxGuideTopicsLimit: 8,
            activeGuideEvent: 'greGuide',
            sections: [
                {
                    id: RESOURCE_TYPE.GRE_GUIDE,
                    title: 'GRE Guide',
                    subtitle: 'A GRE-Specific library of information',
                    icon: greGuideIcon,
                    redirection: ROUTES.GRE_BLOGS_AND_GUIDES,
                    eventFor: 'greGuide',
                    defaultGuideTab: GRE_GUIDES_DATA[0].topic.toLowerCase(),
                    key: PLATFORM_FEATURES.GUIDES
                },
                {
                    id: RESOURCE_TYPE.GMAT_GUIDE,
                    title: 'GMAT Guide',
                    subtitle: 'A GMAT-Specific library of information',
                    icon: gmatGuideIcon,
                    redirection: ROUTES.GMAT_BLOGS_AND_GUIDES,
                    eventFor: 'gmatGuide',
                    defaultGuideTab: GMAT_GUIDES_DATA[0].topic.toLowerCase(),
                    showNewTag: true,
                    key: PLATFORM_FEATURES.GUIDES
                },
                {
                    id: RESOURCE_TYPE.GRE_BLOG,
                    title: 'GRE Blogs',
                    subtitle: 'Expert-curated GRE Tips and Tricks',
                    icon: greBlogIcon,
                    redirection: ROUTES.GRE_BLOG_LISTING,
                    eventFor: 'greBlogs',
                    key: PLATFORM_FEATURES.BLOGS
                },
                {
                    id: RESOURCE_TYPE.GMAT_BLOG,
                    title: 'GMAT Blogs',
                    subtitle: 'Expert-curated GMAT Tips and Tricks',
                    icon: gmatBlogIcon,
                    redirection: ROUTES.GMAT_BLOG_LISTING,
                    eventFor: 'gmatBlogs',
                    showNewTag: true,
                    key: PLATFORM_FEATURES.BLOGS
                }
            ],
            activeSection: {},
            showList: true
        };
    },
    computed: {
        ...mapGetters({
            hasPrepFreebies: 'auth/hasPrepFreebies',
            isFreeTrialExpired: 'auth/isFreeTrialExpired',
            isPremiumUser: 'auth/isPremiumUser',
            featurePermissions: 'auth/featurePermissions',
            isAuthenticated: 'auth/isAuthenticated'
        }),
        allowedSections() {
            if (!this.isAuthenticated) return this.sections;
            return this.sections.filter((item) =>
                isFeatureAllowed(item.key, this.featurePermissions)
            );
        }
    },
    mounted() {
        // Condition to show freebies tab
        if (!this.isPremiumUser && this.hasPrepFreebies && !this.isFreeTrialExpired) {
            this.sections.unshift({
                id: RESOURCE_TYPE.FREEBIES,
                title: 'Your Freebies',
                subtitle: 'Cheatsheet formula and Videos',
                icon: freebiesIcon,
                redirection: ROUTES.FREEBIES,
                eventFor: null
            });
        }
    },

    methods: {
        redirect(value) {
            if (value.external) {
                window.open(value.redirection, '_blank');
            } else {
                this.$router.push(`${value.redirection}`);
            }

            if (value.eventFor) {
                this.emitEvent(value.eventFor, value?.defaultGuideTab);
            }
        },
        emitEvent(event, payload = null) {
            const payloadObjToEmit = {
                eventFor: event
            };

            if (payload) {
                payloadObjToEmit.resource = payload;
            }

            this.$emit('fireEvent', payloadObjToEmit);
        },
        setActiveGuideTopicAndEvent(resourceType) {
            let _guideTopics = this.guideTopics;
            let _guideEvent = 'greGuide';
            switch (resourceType) {
                case RESOURCE_TYPE.GRE_GUIDE: {
                    _guideTopics = GRE_TOPICS;
                    _guideEvent = 'greGuide';
                    break;
                }
                case RESOURCE_TYPE.GMAT_GUIDE: {
                    _guideTopics = GMAT_TOPICS;
                    _guideEvent = 'gmatGuide';
                    break;
                }
                default: {
                    _guideTopics = GRE_TOPICS;
                    _guideEvent = 'greGuide';
                    break;
                }
            }
            this.guideTopics = _guideTopics;
            this.activeGuideEvent = _guideEvent;
        },
        handleOnMouseOver(value) {
            this.setActiveGuideTopicAndEvent(value);
            if ([RESOURCE_TYPE.GMAT_GUIDE, RESOURCE_TYPE.GRE_GUIDE].includes(value)) {
                this.showList = true;
            } else {
                this.showList = false;
            }
        }
    }
};
