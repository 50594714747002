//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
import AuthContainer from '~/components/Auth/AuthContainer.vue';
import GoogleReCaptcha from '~/components/ReusableComponents/GoogleReCaptcha.vue';
import { AUTH_STEP } from '~/constants/authFlows.js';
const CDN = process.env.CDN_ENDPOINT + '/testprep/static/images';
export default {
    name: 'AuthModal',
    components: { GoogleReCaptcha, AuthContainer },
    props: {
        isAcquisitionAuth: {
            type: Boolean,
            default: false
        },
        acquisitionAuthHeading: {
            type: String,
            default: 'Read all you need! No interruptions!'
        },
        acquisitionAuthSubheading: {
            type: String,
            default: 'Just sign up once!'
        },
        webStepZeroSignupHeading: {
            type: String,
            default: 'Sign up to access your test report!'
        },
        webStepZeroSignupSubHeading: {
            type: String,
            default: 'Get insights into your strengths and weakness to prep better.'
        },
        leftPartHeading: {
            type: String,
            default: 'Get ahead of the prep game!'
        },
        leftPartDescription: {
            type: String,
            default:
                'Enjoy a smart and smooth test-taking experience loaded with great features that will refine your performance.'
        },
        illustrationImg: {
            type: String,
            default: `${CDN}/authModalTestCard.webp`
        },
        closable: {
            type: Boolean,
            default: false
        },
        signupType: {
            type: String,
            default: '',
            required: true
        }
    },
    data: () => {
        return {
            CDN
        };
    },
    computed: {
        ...mapState('auth', {
            onboardingStep: 'onboardingStep',
            loginMethod: 'loginMethod',
            userCountry: 'userCountry'
        }),
        isAcquisitionAuthStepZero() {
            return (
                this.isAcquisitionAuth &&
                [AUTH_STEP.PHONE_INPUT, AUTH_STEP.EMAIL_INPUT, AUTH_STEP.GOOGLE_SIGNIN].includes(
                    this.onboardingStep.stepName
                )
            );
        },
        authContainerHeight() {
            if (this.isAcquisitionAuthStepZero) {
                if (this.userCountry === 'IN') {
                    return this.$device.isMobile
                        ? 'min-h-[391px] h-full'
                        : 'min-h-[370px] lg:min-h-[344px] h-full';
                } else {
                    return this.$device.isMobile
                        ? 'min-h-[391px] h-full'
                        : 'min-h-[407px] lg:min-h-[375px] h-full';
                }
            }

            return 'h-full';
        },
        isNonDismissableModal() {
            if (process.client) {
                return Boolean(window.sessionStorage.getItem('acq_auth_shown'));
            }

            return false;
        }
    },
    methods: {
        closeModal() {
            this.$store.commit('auth/setContinueRoute', '');
            this.$emit('closeModal');
        }
    }
};
