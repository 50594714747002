import { PRACTICE_ROUNDS_INIT_DATA } from '~/constants/vocabBuilder.js';

const getInitialCommonState = () => {
    return {
        // 1 => daily suggestions
        // 2 => keep learning
        selectedDashboardTab: { id: '11', label: 'Daily Suggestions' },
        selectedDay: null,

        // Modals state
        showExitPracticeModal: false,

        // UI management
        showAnswersDuringTest: false,
        showWalkthroughPrompt: false,
        showWalkthrough: false
    };
};

const getInitialDashbaordState = () => {
    return {
        dashboardId: null,
        currentDay: 1,
        dailyStatus: {},
        dailyWordCount: 0,
        confidenceWordCounts: { low: 0, medium: 0, high: 0 },
        lmhConfidenceWords: { 1: null, 2: null, 3: null },
        isNewDashboard: false
    };
};

const getInitialPracticeSetState = () => {
    return {
        // Practice Management
        practiceSessionId: null,
        practiceSetId: null,
        practiceSetInstructions: null,
        practiceSetName: '',
        practiceSetWordCount: null,
        practiceSessionDetails: null,
        practiceSetDetails: null,
        practiceWordSet: [],

        // Rounds inside a practice set
        practiceRounds: PRACTICE_ROUNDS_INIT_DATA
    };
};

const getInitialQuizRoundState = () => {
    return {
        activeRound: null,
        activeRoundQuestions: [],
        questionSetInstanceId: null,

        questionSets: {},
        currQuestionIndex: 0,
        currQuestion: null,
        currAnswerPayload: null,

        currRoundCorrectWords: [],
        currRoundWrongWords: [],
        consecutiveCorrectAnswers: 0
    };
};

export const state = () => ({
    ...getInitialCommonState(),
    ...getInitialDashbaordState(),
    ...getInitialPracticeSetState(),
    ...getInitialQuizRoundState()
});

export const actions = {
    async getDashboard({ state, commit }, { testType }) {
        try {
            const {
                data: { data }
            } = await this.$api.vocabBuilder.getDashboard(testType);

            commit('setDashboardId', data.dashboard_id);
            commit('setDailyStatus', data.daily_statuses);
            commit('setCurrentDay', data.current_day_number);
            commit('setSelectedDay', state.selectedDay || data.current_day_number);
            commit('setDailyWordCount', data.daily_words_count);
            commit('setConfidenceWordCounts', data.confidence_counts);
            commit('setPracticeSetInstructions', data.instructions);
            commit('setIsNewDashboard', data.is_new_dashboard);
        } catch (error) {}
    },
    async getPracticeSetData({ state, commit }, { testType }) {
        try {
            const {
                data: { data }
            } = await this.$api.vocabBuilder.startPracticeSet(testType, state.practiceSetId);

            commit('setPracticeSessionDetails', data.practice_session);
            commit('setPracticeSessionId', data.practice_session.test_taker_id);
            commit('setPracticeSetDetails', data.practice_set_details);
            commit('setPracticeWordSet', data.word_set.terms);
            commit('setPracticeRounds');
            commit('setQuestionSets', { roundId: 1, questionSet: data.word_set.terms });
            commit('setActiveRound', 1);
            commit('setCurrQuestion', 0);
        } catch (error) {}
    },
    async fetchQuizQuestionSet({ state, commit }, { testType, roundId }) {
        const response = { state: false };

        try {
            const { data } = await this.$api.vocabBuilder.fetchQuizQuestionSet(testType, {
                questionSetId:
                    roundId === 2
                        ? state.practiceSetDetails.qset1_id
                        : state.practiceSetDetails.qset2_id,
                practiceSessionId: state.practiceSessionId
            });

            response.state = data.state;
            commit('setQuestionSets', { roundId, questionSet: data.data.questions });
            commit('setQuestionSetInstanceId', data.data.question_set_session_id);
        } catch (error) {
            response.state = false;
        }

        return response;
    },
    async goToQuestion({ state, commit }, { testType, questionIndex }) {
        const response = { state: true };
        // Reading Round => mark the current question as submitted and then change the question
        if (!state.activeRound.isTestBasedRound) {
            commit('setCurrQuestionStatus', {
                isSubmitted: true,
                isCorrect: true
            });
            commit('setCurrQuestion', questionIndex);
        }
        // Quiz Round => submit as a skipped question(status = 2) if submitting first time and then change the question
        else if (!state.currQuestion.status.isSubmitted && !state.currQuestion.status.isSkipped) {
            try {
                const apiPayload = {
                    practiceSessionId: state.practiceSessionId,
                    questionId: state.currQuestion.id,
                    data: {
                        question_set_instance_id: state.questionSetInstanceId,
                        answer_option: undefined,
                        status: 2
                    }
                };
                await this.$api.vocabBuilder.submitAnswer(testType, apiPayload);
                commit('setQuestionAsSkipped', state.currQuestion.index);
                commit('setCurrQuestion', questionIndex);
            } catch (error) {
                response.state = false;
            }
        }

        // Already submitted atleast once
        else {
            commit('setCurrQuestion', questionIndex);
        }

        return response;
    },
    async submitQuestionAsSkipped({ state, commit }, { testType }) {
        const response = { state: true };
        try {
            const apiPayload = {
                practiceSessionId: state.practiceSessionId,
                questionId: state.currQuestion.id,
                data: {
                    question_set_instance_id: state.questionSetInstanceId,
                    answer_option: undefined,
                    status: 2
                }
            };
            await this.$api.vocabBuilder.submitAnswer(testType, apiPayload);
            commit('setQuestionAsSkipped', state.currQuestion.index);
        } catch (error) {
            response.state = false;
        }
        return response;
    },
    async submitAnswer({ state, commit }, { testType }) {
        const response = { state: true };
        try {
            const practiceSessionId = state.practiceSessionId;
            const questionId = state.currQuestion.id;
            commit('createAnswerPayload');

            const apiPayload = {
                practiceSessionId,
                questionId,
                data: state.currAnswerPayload
            };
            const {
                data: { data }
            } = await this.$api.vocabBuilder.submitAnswer(testType, apiPayload);

            const currQuestionStatus = {
                isSubmitted: true,
                isCorrect: data.is_correct,
                correctAnswers: data.correct_answers
            };
            commit('setCurrQuestionStatus', currQuestionStatus);

            // TODO - move the word in correct or wrong list after answer submission
            if ('isCorrect' in currQuestionStatus && currQuestionStatus.isCorrect) {
                commit('setConsecutiveCorrectAnswers', currQuestionStatus.isCorrect);
            }
        } catch (error) {
            response.state = false;
            commit('resetCurrQuestionStatus');
            commit('resetAnswer');
            commit('resetCurrQuestionStatus');
        }

        return response;
    }
};

export const mutations = {
    setDashboardId(state, value) {
        state.dashboardId = value;
    },
    setSelectedDashboardTab(state, value) {
        state.selectedDashboardTab = value;
    },
    setCurrentDay(state, value) {
        state.currentDay = value;
    },
    setSelectedDay(state, value) {
        // selected day should not be greater than last day
        state.selectedDay = Math.min(value, Object.keys(state.dailyStatus).length);
    },
    setDailyStatus(state, value) {
        state.dailyStatus = value;
    },
    setDailyWordCount(state, value) {
        state.dailyWordCount = value;
    },
    setConfidenceWordCounts(state, value) {
        state.confidenceWordCounts = value;
    },
    setLmhConfidenceWords(state, { confidenceLevel, wordList }) {
        state.lmhConfidenceWords[confidenceLevel] = wordList;
    },
    updateLmhConfidenceWordData(state, { confidenceLevel, wordIndex, updatedData }) {
        state.lmhConfidenceWords[confidenceLevel][wordIndex] = updatedData;
    },
    setPracticeSetInstructions(state, value) {
        state.practiceSetInstructions = value;
    },
    setIsNewDashboard(state, value) {
        state.isNewDashboard = value;
    },
    setPracticeSet(state, practiceSet) {
        state.practiceSetId = practiceSet.id;
        state.practiceSetName = practiceSet.name;
        state.practiceSetWordCount = practiceSet.words_count;
    },
    setPracticeSessionDetails(state, payload) {
        state.practiceSessionDetails = payload;
    },
    setPracticeSessionId(state, value) {
        state.practiceSessionId = value;
    },
    setPracticeSetDetails(state, payload) {
        state.practiceSetDetails = payload;
    },
    setPracticeWordSet(state, payload) {
        state.practiceWordSet = payload;
    },
    setPracticeRounds(state) {
        // Reading Round
        state.practiceRounds[0].questionCount = Number(state.practiceSetDetails.words_count);
        state.practiceRounds[0].isComplete = state.practiceSessionDetails.is_term_set_complete;

        // Quiz 1
        state.practiceRounds[1].questionCount = Number(state.practiceSetDetails.quiz1_count);
        state.practiceRounds[1].isComplete = state.practiceSessionDetails.is_qset1_complete;

        // Quiz 2
        state.practiceRounds[2].questionCount = Number(state.practiceSetDetails.quiz2_count);
        state.practiceRounds[2].isComplete = state.practiceSessionDetails.is_qset2_complete;
    },
    setQuestionSets(state, { roundId, questionSet }) {
        state.questionSets[roundId] = questionSet;
    },
    setQuestionSetInstanceId(state, data) {
        state.questionSetInstanceId = data;
    },
    setActiveRound(state, roundId) {
        // Step 1 - Set Actice Round Id - 1/2/3
        const currentRound = state.practiceRounds.find((round) => round.id === roundId);
        state.activeRound = { ...currentRound };

        // Step 2 - Determine the current question set and prepare an array of formatted object with response status
        const questionSet = state.questionSets[roundId];

        state.activeRoundQuestions = questionSet.map((question, index) => {
            return {
                id: question.id,
                index,
                question,
                markedAnswers: [],
                correctAnswers: [],
                status: {
                    disabled: true,
                    isSubmitted: false,
                    isMarked: false,
                    isSkipped: false,
                    isCorrect: false
                }
            };
        });
    },
    setCurrQuestion(state, index) {
        state.currQuestionIndex = index;
        state.activeRoundQuestions[index].status.disabled = false;
        state.currQuestion = state.activeRoundQuestions[index];
    },
    setQuestionAsSkipped(state, index) {
        state.activeRoundQuestions[index].status.isSkipped = true;
    },
    endCurrentRound(state) {
        if (!state.activeRound) {
            return;
        }

        state.practiceRounds.forEach((round) => {
            if (round.id === state.activeRound.id) {
                round.isComplete = true;
            }
        });
        state.activeRound = null;
    },
    setExitPracticeModal(state, value) {
        state.showExitPracticeModal = value;
    },
    storeCurrentAnswer(state, payload) {
        const { answer, type, index } = payload;
        if (type === 'mcq_s' || type === 'mcq_m') {
            state.activeRoundQuestions[index].markedAnswers = answer;
        }
    },
    createAnswerPayload(state) {
        // Get the answers id/ids in answer_option
        let answerOption = [];
        if (state.currQuestion.markedAnswers && state.currQuestion.markedAnswers.length > 0) {
            answerOption = state.currQuestion.markedAnswers.map((item) => item.answer_id);
        }

        state.currAnswerPayload = {
            question_set_instance_id: state.questionSetInstanceId,
            answer_option: answerOption.length > 0 ? answerOption : undefined,
            status: answerOption.length > 0 ? 1 : 2
        };
    },
    setCurrQuestionStatus(state, { isSubmitted, isCorrect, correctAnswers = [] }) {
        state.currQuestion.status.isSubmitted = isSubmitted;
        state.currQuestion.status.isCorrect = isCorrect;
        state.currQuestion.correctAnswers = correctAnswers;
    },
    populateCorrectAndWrongWordsList(state) {
        state.activeRoundQuestions.forEach((question) => {
            const questionTermId = question.question.term_id;
            const wordDetails = state.practiceWordSet.find((word) => word.id === questionTermId);
            if (!wordDetails) return;

            const isQuestionCorrect = question.status.isSubmitted && question.status.isCorrect;
            isQuestionCorrect
                ? state.currRoundCorrectWords.push(wordDetails)
                : state.currRoundWrongWords.push(wordDetails);
        });
    },
    setConsecutiveCorrectAnswers(state, isCorrect) {
        state.consecutiveCorrectAnswers = isCorrect ? state.consecutiveCorrectAnswers + 1 : 0;
    },
    resetDashboardData(state, statesList) {
        const initialState = getInitialDashbaordState();
        const list = statesList || Object.keys(initialState);
        for (const property of list) {
            state[property] = initialState[property];
        }
    },
    resetPracticeSetData(state, statesList) {
        const initialState = getInitialPracticeSetState();
        const list = statesList || Object.keys(initialState);
        for (const property of list) {
            state[property] = initialState[property];
        }
    },
    resetQuizRoundData(state, statesList) {
        const initialState = getInitialQuizRoundState();
        const list = statesList || Object.keys(initialState);
        for (const property of list) {
            state[property] = initialState[property];
        }
    },
    setShowWalkthrough(state, data) {
        state.showWalkthrough = data;
    },
    setShowWalkthroughPrompt(state, data) {
        state.showWalkthroughPrompt = data;
    }
};

export const getters = {
    selectedDashboardTab(state) {
        return state.selectedDashboardTab;
    },
    dashboardId(state) {
        return state.dashboardId;
    },
    currentDay(state) {
        return state.currentDay;
    },
    selectedDay(state) {
        return state.selectedDay;
    },
    dailyStatus(state) {
        return state.dailyStatus;
    },
    dailyWordCount(state) {
        return state.dailyWordCount;
    },
    confidenceWordCounts(state) {
        return state.confidenceWordCounts;
    },
    isNewDashboard(state) {
        return state.isNewDashboard;
    },
    practiceSetName(state) {
        return state.practiceSetName;
    },
    practiceSetInstructions(state) {
        return {
            practice_set_name: state.practiceSetName,
            word_count: state.practiceSetWordCount,
            round_count: state.practiceSetInstructions.rounds_count,
            instructions: state.practiceSetInstructions.instructions_list
        };
    },
    practiceSessionId(state) {
        return state.practiceSessionId;
    },
    practiceSessionDetails(state) {
        return state.practiceSessionDetails;
    },
    practiceSetDetails(state) {
        return state.practiceSetDetails;
    },
    practiceWordSet(state) {
        return state.practiceWordSet;
    },
    practiceRounds(state) {
        return state.practiceRounds;
    },
    activeRound(state) {
        return state.activeRound;
    },
    questionSetInstanceId(state) {
        return state.questionSetInstanceId;
    },
    activeRoundQuestions(state) {
        return state.activeRoundQuestions;
    },
    currQuestionIndex(state) {
        return state.currQuestionIndex;
    },
    currQuestion(state) {
        return state.currQuestion;
    },
    consecutiveCorrectAnswers(state) {
        return state.consecutiveCorrectAnswers;
    },
    isLastQuestion(state) {
        return state.currQuestion.index === state.activeRoundQuestions.length - 1;
    },
    currRoundCorrectWords(state) {
        return state.currRoundCorrectWords;
    },
    currRoundWrongWords(state) {
        return state.currRoundWrongWords;
    },
    allQuestionsVisited(state) {
        // if any of the question is disabled that means it is not yet visited
        for (let index = 0; index < state.activeRoundQuestions.length; index++) {
            if (state.activeRoundQuestions[index].status.disabled) return false;
        }
        return true;
    },
    lastCompleteRound(state) {
        for (let index = 0; index < state.practiceRounds.length; index++) {
            if (!state.practiceRounds[index].isComplete) {
                return index;
            }
        }
        return 0;
    },
    lmhConfidenceWords(state) {
        return state.lmhConfidenceWords;
    },
    showExitPracticeModal(state) {
        return state.showExitPracticeModal;
    },
    showWalkthrough(state) {
        return state.showWalkthrough;
    },
    showWalkthroughPrompt(state) {
        return state.showWalkthroughPrompt;
    }
};
