import { DASHBOARD_MODES } from '~/static/constants';
const getInitialState = () => {
    return {
        dashboardMode: DASHBOARD_MODES.PRACTICE,
        dashboardData: null, // All the data of the dashboard
        dashboardType: null, // Type of the dashboard (quick or full)
        showDashboardLoading: false,
        showReportLoader: false,
        lastSelectedTab: null,
        scrollReportToBottom: false,
        activeLearningSection: null,
        activeLearningTopic: null,
        activePracticeSection: null,
        activePracticeTopic: null,
        goToTestId: null,
        openInstructionOnTestFocus: true, // handles when to default open the instructions when redirecting from planner or freebies/banner
        greLiveTestId: null,
        isFullMockSectionActive: false,

        doNotResetView: false
    };
};

export const state = () => ({
    ...getInitialState()
});

export const actions = {
    async getDashboardData({ state, commit }, { testType }) {
        const response = {};
        try {
            const {
                data: { data, state: status }
            } = await this.$api.dashboard.getDashboardData(testType);

            response.state = data.state;
            if (status) {
                commit('setDashboardData', data.dashboard);
                commit('setDashboardType', data.dashboard_type);

                // Set default active section and subsection.
                // Assuming by default we will have Verbal Selected.
                // If we do not want to update the view, lets not update the section data.
                if (!state.activePracticeSection)
                    commit('setActivePracticeSection', data?.dashboard[0].sub_sections[0]);

                // setting the full diagnostic data in TESTSETS store
                if (data.full_diag_test) {
                    this.commit('testSets/setDiagnosticTestData', {
                        diagnosticTestType: 'FD',
                        diagnosticTestData: data.full_diag_test
                    });
                }

                commit('setGreLiveTestId', data.report_test_taker_ids?.liveFull);
            }
        } catch (error) {
            response.state = false;
        }
        return response;
    }
};

export const mutations = {
    setDashboardData(state, value) {
        state.dashboardData = value;
    },
    setDashboardType(state, value) {
        state.dashboardType = value;
    },
    setShowDashboardLoading(state, value) {
        state.showDashboardLoading = value;
    },
    setShowReportLoader(state, value) {
        state.showReportLoader = value;
    },
    setLastSelectedTab(state, value) {
        state.lastSelectedTab = value;
    },
    setScrollReportToBottom(state, value) {
        state.scrollReportToBottom = value;
    },
    setDashboardMode(state, value) {
        state.dashboardMode = value;
        window.localStorage.setItem('_lst_actv_md', value);
    },
    resetDashboardMode() {
        window.localStorage.removeItem('_lst_actv_md');
    },
    setActiveLearningSection(state, value) {
        state.activeLearningSection = value;
    },
    setActiveTopic(state, value) {
        state.activeLearningTopic = value;
    },
    setActivePracticeSection(state, value) {
        state.activePracticeSection = value;
    },
    setActivePracticeTopic(state, value) {
        state.activePracticeTopic = value;
    },
    setGoToTestId(state, value) {
        state.goToTestId = value;
    },
    setDoNotResetView(state, value) {
        state.doNotResetView = value;
    },
    setGreLiveTestId(state, value) {
        state.greLiveTestId = value;
    },
    setFullMockSectionActive(state, value) {
        state.isFullMockSectionActive = value;
    },
    setOpenInstructionOnTestFocus(state, value) {
        state.openInstructionOnTestFocus = value;
    }
};

export const getters = {
    dashboardData(state) {
        return state.dashboardData;
    },
    dashboardType(state) {
        return state.dashboardType;
    },
    showDashboardLoading(state) {
        return state.showDashboardLoading;
    },
    showReportLoader(state) {
        return state.showReportLoader;
    },
    lastSelectedTab(state) {
        return state.lastSelectedTab;
    },
    scrollReportToBottom(state) {
        return state.scrollReportToBottom;
    },
    dashboardMode(state) {
        return state.dashboardMode;
    },
    // This will decide active section.
    activeLearningSection(state) {
        return state.activeLearningSection;
    },
    // This will decide active topic view
    activeLearningTopic(state) {
        return state.activeLearningTopic;
    },
    // PS: above both getters will determine the view in DBVIEW:001 (global search it).
    activePracticeSection(state) {
        return state.activePracticeSection;
    },
    activePracticeTopic(state) {
        return state.activePracticeTopic;
    },
    goToTestId(state) {
        return state.goToTestId;
    },
    doNotResetView(state) {
        return state.doNotResetView;
    },
    greLiveTestId(state) {
        return state.greLiveTestId;
    },
    isFullMockSectionActive(state) {
        return state.isFullMockSectionActive;
    },
    openInstructionOnTestFocus(state) {
        return state.openInstructionOnTestFocus;
    }
};
