export default (http, resource, apiVersion) => ({
    getNewSectionQuestions(testType, testSetId, data) {
        const method = 'post';
        const url = `${apiVersion}/${resource}/${testType}/${testSetId}`;
        return http({ method, url, data });
    },
    getResumedSectionQuestions(testType, testTakerId) {
        const method = 'get';
        const url = `${apiVersion}/${resource}/${testType}/resume/${testTakerId}`;
        return http({ method, url });
    }
});
