export const state = () => ({
    dashboardData: null,
    activeVideo: null,
    // Used to fetch video using ID.
    gotoActiveVideoId: null
});

export const actions = {
    async getLearningDashboardData({ commit }, { testType }) {
        const response = {};
        try {
            const {
                data: { data }
            } = await this.$api.learningDashboard.getLearningDashboardData(testType);

            commit('setDashboardData', data);
        } catch (error) {
            response.state = false;
        }
        return response;
    }
};

export const mutations = {
    setDashboardData(state, value) {
        state.dashboardData = value;
    },
    setGotoActiveVideoId(state, value) {
        state.gotoActiveVideoId = value;
    },
    setActiveVideoDetails(state, value) {
        state.activeVideo = value;
    }
};

export const getters = {
    learningDashboardData(state) {
        return state.dashboardData;
    },
    gotoActiveVideoId(state) {
        return state.gotoActiveVideoId;
    },
    activeVideo(state) {
        return state.activeVideo;
    }
};
