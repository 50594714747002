var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex box-border bg-white min-w-[546px] min-h-[364px] rounded-xl overflow-hidden"},[_c('div',{staticClass:"flex flex-col py-6",class:("" + (_vm.showList ? 'min-w-[383px] border-r ' : 'w-full'))},_vm._l((_vm.allowedSections),function(item,index){return _c('div',{key:("resource_item-" + index),staticClass:"group relative flex space-x-5 py-3 px-8 border-l-4 border-l-transparent hover:cursor-pointer hover:bg-neutral-50 border-y border-y-transparent hover:border-y-neutral-200",class:{
                'hover:border-l-[#E37712] hover:text-[#E37712]':
                    item.id === _vm.RESOURCE_TYPE.FREEBIES,
                'hover:border-l-[#6D28D9] hover:text-theme-primary':
                    item.id === _vm.RESOURCE_TYPE.GRE_GUIDE,
                'hover:border-l-yocket-blue-700 hover:text-yocket-blue-700':
                    item.id === _vm.RESOURCE_TYPE.GMAT_GUIDE,
                'hover:border-l-[#00838A] hover:text-[#00838A]':
                    item.id === _vm.RESOURCE_TYPE.GRE_BLOG,
                'hover:border-l-[#A16207] hover:text-[#A16207]':
                    item.id === _vm.RESOURCE_TYPE.GMAT_BLOG
            },on:{"click":function($event){return _vm.redirect(item)},"mouseover":function($event){return _vm.handleOnMouseOver(item.id)}}},[_c('img',{staticClass:"h-10 w-10",attrs:{"src":item.icon,"height":"40px","width":"40px"}}),_vm._v(" "),_c('div',{staticClass:"flex flex-col box-border w-full text-sm"},[_c('p',{staticClass:"flex items-start"},[_c('span',{staticClass:"font-bold"},[_vm._v(_vm._s(item.title))]),_vm._v(" "),(item.showNewTag)?_c('span',{staticClass:"text-theme-primary font-bold text-[10px] leading-[10px] px-1 pt-0.5 pb-[3px] mx-2 rounded-sm bg-gradient-to-r from-[#F3EAFF] to-[#D8B9FF]"},[_vm._v("\n                        New\n                    ")]):_vm._e()]),_vm._v(" "),_c('p',{staticClass:"text-theme-gray-dark"},[_vm._v(_vm._s(item.subtitle))])]),_vm._v(" "),(_vm.showList)?_c('div',{staticClass:"absolute -right-[1px] w-0.5 top-0 h-full bg-neutral-50 hidden group-hover:block"}):_vm._e()])}),0),_vm._v(" "),(_vm.showList)?_c('div',{staticClass:"py-5 px-4 rounded-tr-xl",class:("" + (_vm.showList ? 'w-1/3 bg-neutral-50' : ''))},[_c('ul',{staticClass:"rounded-lg"},_vm._l((_vm.guideTopics.slice(0, _vm.maxGuideTopicsLimit + 1)),function(item,index){return _c('li',{key:index,staticClass:"flex text-sm font-medium hover:font-bold",on:{"click":function($event){_vm.showDropDown = false}}},[(index < _vm.maxGuideTopicsLimit)?_c('NuxtLink',{staticClass:"py-2 px-3",attrs:{"to":("" + (item.redirection))},nativeOn:{"click":function($event){_vm.emitEvent(_vm.activeGuideEvent, item.topic.toLowerCase())}}},[_vm._v("\n                    "+_vm._s(item.topic)+"\n                ")]):_c('NuxtLink',{staticClass:"py-2 px-3 text-theme-primary font-bold",attrs:{"to":("" + (_vm.guideTopics[0].redirection))},nativeOn:{"click":function($event){_vm.emitEvent(_vm.activeGuideEvent, _vm.guideTopics[0].topic.toLowerCase())}}},[_vm._v("\n                    View all\n                ")])],1)}),0)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }