//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import { DASHBOARD_REDIRECTION, DASHBOARD_MODES } from '~/static/constants.js';
import { ROUTES } from '~/constants/pageRoutes';
import { isFeatureAllowed } from '~/services/utilityFunctions';

export default {
    name: 'DashboardSelector',
    data() {
        return {
            DASHBOARD_REDIRECTION,
            DASHBOARD_MODES
        };
    },
    computed: {
        ...mapGetters({
            dashboardMode: 'dashboard/dashboardMode',
            featurePermissions: 'auth/featurePermissions'
        }),
        isDashboardPage() {
            return this.$route.path.includes(ROUTES.DASHBOARD);
        },
        allowedDashboardRedirections() {
            return this.DASHBOARD_REDIRECTION.filter((item) =>
                isFeatureAllowed(item.key, this.featurePermissions)
            );
        }
    },
    methods: {
        redirectToDashboard(value) {
            if (value === DASHBOARD_MODES.LEARNING) {
                this.$store.commit('dashboard/setDashboardMode', DASHBOARD_MODES.LEARNING);
                this.$emit('fireEvent', 'learningDashboard');
            } else {
                this.$store.commit('dashboard/setDashboardMode', DASHBOARD_MODES.PRACTICE);
                this.$emit('fireEvent', 'practiceDashboard');
            }

            this.$router.push(ROUTES.DASHBOARD);
        }
    }
};
