//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex';
import RedirectionCard from '~/components/Navbar/Web/RedirectionCard.vue';
import { ROUTES } from '~/constants/pageRoutes';
import { isFeatureAllowed } from '~/services/utilityFunctions';
import { PLATFORM_FEATURES } from '~/static/constants';

export default {
    name: 'PrepToolsSelector',
    components: {
        RedirectionCard
    },

    data() {
        return {
            prepTools: [
                {
                    title: 'Study Planner',
                    subtitle: 'Create an organised study plan',
                    bgConfig: 'bg-cover bg-[url(~/static/images/plannerBg.png)]',
                    key: PLATFORM_FEATURES.STUDY_PLANNER,
                    redirection: ROUTES.PLANNER_LP, // Navguard will automatically reroute it to plan page if it is set
                    eventFor: 'planner'
                },
                {
                    title: 'Vocab Builder',
                    subtitle: 'Learn 20 new words everyday',
                    bgConfig: 'bg-cover bg-[url(~/static/images/vocabBg.png)]',
                    key: PLATFORM_FEATURES.VOCAB_BUILDER,
                    redirection: ROUTES.VOCAB,
                    eventFor: 'vocabConnect'
                }
            ]
        };
    },
    computed: {
        ...mapGetters({
            featurePermissions: 'auth/featurePermissions',
            isAuthenticated: 'auth/isAuthenticated'
        }),
        allowedPrepTools() {
            if (!this.isAuthenticated) return this.prepTools;
            return this.prepTools.filter((item) =>
                isFeatureAllowed(item.key, this.featurePermissions)
            );
        }
    }
};
