export const BLOG_TOPICS = Object.freeze({
    GMAT: 'gmat',
    GRE: 'gre'
});
export const BLOG_TITLES = Object.freeze({
    [BLOG_TOPICS.GMAT]: {
        title: 'GMAT Blogs: Everything You Need to Learn About GMAT | Yocket Prep'
    },
    [BLOG_TOPICS.GRE]: {
        title: 'GRE Blogs: Everything You Need to Learn About GRE | Yocket Prep'
    }
});
export const BLOG_HEADER_INFO = Object.freeze({
    [BLOG_TOPICS.GRE]: {
        title: 'Strengthen your GRE Prep!',
        subTitle: 'Stay ahead of the competition by reading these expert curated GRE Prep articles.'
    },
    [BLOG_TOPICS.GMAT]: {
        title: 'Master the GMAT: Tips, Strategies, and Insights for Success',
        subTitle:
            'Discover top GMAT strategies, study tips, and success stories to help you score higher. Your journey to a top business school starts here.'
    }
});

export const BLOG_META_INFO = Object.freeze({
    [BLOG_TOPICS.GMAT]: {
        title: 'Complete GAMT Exam Prep Planner Online | Yocket Prep',
        description: 'Complete GAMT Exam Prep Planner Online | Yocket Prep'
    },
    [BLOG_TOPICS.GRE]: {
        title: 'Complete GRE Exam Prep Planner Online | Yocket Prep',
        description: 'Complete GRE Exam Prep Planner Online | Yocket Prep'
    }
});

export const BLOG_TOPICS_SOURCE = Object.freeze({
    GRE_BLOGS: 'gre_blogs',
    GMAT_BLOGS: 'gmat_blogs'
});

export const BLOG_TOPIC_TO_SOURCE_MAPPING = Object.freeze({
    [BLOG_TOPICS.GMAT]: BLOG_TOPICS_SOURCE.GMAT_BLOGS,
    [BLOG_TOPICS.GRE]: BLOG_TOPICS_SOURCE.GRE_BLOGS
});
