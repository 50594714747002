import { PREMIUM_PLANS } from '~/static/constants';

const getInitialState = () => {
    return {
        allPlans: null,
        groupedPlans: null,
        faqs: null,
        testimonials: null,
        selectedPlan: null,
        selectedPlanUpgradeDetails: null,
        razorpayOrderId: '',
        allCoupons: [],
        defaultSelectedCoupon: null,
        orderDetails: null,

        addressLine1: '',
        addressLine2: '',
        city: '',
        pinCode: '',
        state: '',
        billingAddress: '',
        addressError: '',
        cityError: '',
        pinCodeError: '',
        stateError: '',

        exitCheckoutCoupon: null,
        appliedCouponCode: '',
        hasAppliedCoupon: false,

        // NORMAL_COUPON: 1,
        // REFERRAL_CAMPAIGN_COUPON: 2,
        // YOCKET_PREMIUM_DISCOUNT: 3
        couponType: null,
        showCouponTimer: false,

        showContinuePaymentLoader: false,
        showLoader: true,

        originalPrice: 0,
        finalPrice: 0,

        referralDetails: null,
        isZeroPaymentOrder: false,
        showUpsellingModalOnRedirection: false
    };
};

export const state = () => ({
    ...getInitialState()
});

export const actions = {
    async getPricingPageData({ commit, state }, payload) {
        // if payload exists, it will always contain both couponCode and planSlug
        try {
            const { testType, couponCode, planSlug } = payload || {};
            const {
                data: { data }
            } = await this.$api.premium.getAllPremiumPlans(testType, {
                couponCode,
                planSlug
            });

            commit('setAllPlans', data.plans);
            commit('setGroupedPlans');
            commit('setFaqs', data.faqs);
            commit('setTestimonials', data.testimonials);
            commit(
                'setDefaultSelectedCoupon',
                data.coupon_validation && data.coupon_validation.coupon
                    ? data.coupon_validation
                    : null
            ); // null if no coupon

            commit(
                'setDefaultSelectedPlan',
                data.coupon_validation?.plan_key ?? state.selectedPlan?.slug
            ); // undefined if no plan

            // commit upcoming live class batch details to classes store
            commit('classes/setUpcomingBatchDetails', data.upcoming_batch, { root: true });
        } catch (error) {}
    },
    async createPremiumOrder({ commit }, payload) {
        const response = {};
        try {
            const { testType, ...newPayload } = payload;
            const {
                data: { data, state }
            } = await this.$api.premium.createPremiumOrder(testType, newPayload);

            if (state) {
                const razorpayOrderId = data.order_id;
                commit('setRazorPayOrderId', razorpayOrderId);
                response.data = data;
            }

            response.state = state;
        } catch (error) {
            response.error = error.response.data.errors.message;
            response.state = false;
        }
        return response;
    },
    async getSubscriptionDetails({ commit }, { testType }) {
        const response = {};
        try {
            const {
                data: { data, state }
            } = await this.$api.premium.getSubscriptionOrderDetails(testType);
            if (state) {
                commit('setOrderDetails', data.subscription_order_detail);
                response.data = data;
            }

            response.state = state;
        } catch (error) {
            response.state = false;
        }
        return response;
    },
    checkValidAddress({ state, commit }) {
        if (!state.billingAddress) {
            commit('setAddressError', 'Kindly, provide us your billing address');
        }

        if (!state.city) {
            commit('setCityError', 'Kindly, provide us your city');
        }

        if (!state.state) {
            commit('setStateError', 'Kindly, provide us your state');
        }

        if (!state.pinCode) {
            commit('setPinCodeError', 'Kindly, provide us your pincode');
        }

        const isNotValidAddress = Boolean(
            state.addressError || state.pinCodeError || state.cityError || state.stateError
        );
        return !isNotValidAddress;
    },

    async getUserReferralDetails({ commit }, { testType }) {
        const response = {};
        try {
            const {
                data: { data, state }
            } = await this.$api.premium.getUserReferralDetails(testType);
            if (state) {
                commit('setReferralDetails', {
                    referralUrl: data.referral_url,
                    isReferralRewardEligible: data.is_referral_reward_eligible,
                    referralRewardCoupon: data.referral_reward_coupon,
                    referralCampaign: data.referral_campaign || {}
                });
            }

            response.state = state;
        } catch (error) {
            response.state = false;
        }
        return response;
    }
};

export const mutations = {
    setInitialPaymentState(state) {
        state.addressLine1 = '';
        state.addressLine2 = '';
        state.city = '';
        state.pinCode = '';
        state.state = '';
        state.billingAddress = '';
        state.addressError = '';
        state.cityError = '';
        state.pinCodeError = '';
        state.stateError = '';

        state.appliedCouponCode = '';
        state.hasAppliedCoupon = false;

        state.showContinuePaymentLoader = false;
        state.showLoader = true;
    },
    setAllPlans(state, data) {
        state.allPlans = data;
    },
    setGroupedPlans(state) {
        if (!state.allPlans) return;

        const groupedPlans = {};
        for (const [category, plans] of Object.entries(state.allPlans)) {
            const groups = [];
            plans.forEach((plan) => {
                const isGroupPresent = groups.find((group) => group.key === plan.group.key);
                if (!isGroupPresent) {
                    if (plan.group) groups.push({ ...plan.group, category });
                }
            });

            // Filter Plans for each group
            groups.forEach((group) => {
                const plansInGroup = plans.filter((plan) => plan.group.key === group.key);
                group.plans = plansInGroup;
            });

            // Assign the plans in the group
            groupedPlans[category] = groups;
        }

        state.groupedPlans = groupedPlans;
    },
    setSelectedPlan(state, data) {
        state.selectedPlan = data;
    },
    setSelectedPlanUpgradeDetails(state, data) {
        state.selectedPlanUpgradeDetails = data;
    },
    setDefaultSelectedPlan(state, planSlug) {
        if (!state.allPlans) return;

        const planToSet = planSlug ?? PREMIUM_PLANS.PREP_PLUS_ONDEMAND;

        // Find the plan in all plans, if not present select the first plan of complete prep category
        let defaultPlan = [...state.allPlans.complete_prep, ...state.allPlans.only_practice].find(
            (plan) => plan.slug === planToSet
        );

        if (!defaultPlan) {
            defaultPlan = state.allPlans.complete_prep[0];
        }
        state.selectedPlan = defaultPlan;
    },
    setFaqs(state, data) {
        state.faqs = data;
    },
    setTestimonials(state, data) {
        state.testimonials = data;
    },
    setRazorPayOrderId(state, data) {
        state.razorpayOrderId = data;
    },
    setAllCoupons(state, data) {
        state.allCoupons = data;
    },
    setDefaultSelectedCoupon(state, data) {
        state.defaultSelectedCoupon = data;
    },
    setOrderDetails(state, data) {
        state.orderDetails = data;
    },
    setAddress(state, payload) {
        state.addressLine1 = payload.addressLine1;
        state.addressLine2 = payload.addressLine2;
        state.city = payload.city;
        state.pinCode = payload.pinCode;
        state.state = payload.state;
        state.billingAddress = (payload.addressLine1 + ' ' + payload.addressLine2).trim();
    },
    clearError(state) {
        state.addressError = '';
        state.pinCodeError = '';
        state.cityError = '';
        state.stateError = '';
    },
    setHasAppliedCoupon(state, data) {
        state.hasAppliedCoupon = data;
    },
    setAppliedCouponCode(state, data) {
        state.appliedCouponCode = data;
    },
    setCouponType(state, data) {
        state.couponType = data;
    },
    setAddressError(state, data) {
        state.addressError = data;
    },
    setCityError(state, data) {
        state.cityError = data;
    },
    setStateError(state, data) {
        state.stateError = data;
    },
    setPinCodeError(state, data) {
        state.pinCodeError = data;
    },
    setShowContinuePaymentLoader(state, data) {
        state.showContinuePaymentLoader = data;
    },
    setOriginalPrice(state, data) {
        state.originalPrice = data;
    },
    setFinalPrice(state, data) {
        state.finalPrice = data;
    },
    setShowLoader(state, data) {
        state.showLoader = data;
    },
    setReferralDetails(state, referralDetails) {
        state.referralDetails = referralDetails;
    },
    setIsZeroPaymentOrder(state, data) {
        state.isZeroPaymentOrder = data;
    },
    setCouponTimer(state, data) {
        state.showCouponTimer = data;
    },
    setExitCheckoutCoupon(state, data) {
        state.exitCheckoutCoupon = data;
    },
    setShowUpsellingModalOnRedirection(state, data) {
        state.showUpsellingModalOnRedirection = data;
    }
};

export const getters = {
    allPlans(state) {
        return state.allPlans;
    },
    combinedPlans(state) {
        return state.allPlans
            ? [...state.allPlans.only_practice, ...state.allPlans.complete_prep]
            : [];
    },
    groupedPlans(state) {
        return state.groupedPlans;
    },
    selectedPlan(state) {
        return state.selectedPlan;
    },
    selectedPlanUpgradeDetails(state) {
        return state.selectedPlanUpgradeDetails;
    },
    faqs(state) {
        return state.faqs;
    },
    testimonials(state) {
        return state.testimonials;
    },
    razorpayOrderId(state) {
        return state.razorpayOrderId;
    },
    allCoupons(state) {
        return state.allCoupons;
    },
    defaultSelectedCoupon(state) {
        return state.defaultSelectedCoupon;
    },
    orderDetails(state) {
        return state.orderDetails;
    },
    appliedCouponCode(state) {
        return state.appliedCouponCode;
    },
    addressError(state) {
        return state.addressError;
    },
    cityError(state) {
        return state.cityError;
    },
    stateError(state) {
        return state.stateError;
    },
    pinCodeError(state) {
        return state.pinCodeError;
    },
    hasAppliedCoupon(state) {
        return state.hasAppliedCoupon;
    },
    couponType(state) {
        return state.couponType;
    },
    showContinuePaymentLoader(state) {
        return state.showContinuePaymentLoader;
    },
    addressDetails(state) {
        return {
            addressLine1: state.addressLine1,
            addressLine2: state.addressLine2,
            city: state.city,
            pinCode: state.pinCode,
            state: state.state,
            billingAddress: state.billingAddress
        };
    },
    originalPrice(state) {
        return state.originalPrice;
    },
    finalPrice(state) {
        return state.finalPrice;
    },
    showLoader(state) {
        return state.showLoader;
    },
    userReferralDetails(state) {
        return state.referralDetails;
    },
    isZeroPaymentOrder(state) {
        return state.isZeroPaymentOrder;
    },
    showCouponTimer(state) {
        return state.showCouponTimer;
    },
    exitCheckoutCoupon(state) {
        return state.exitCheckoutCoupon;
    },
    showUpsellingModalOnRedirection(state) {
        return state.showUpsellingModalOnRedirection;
    }
};
