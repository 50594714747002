//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import analyticEvents from '~/services/analyticEvents';
import { yocketLogo, yocketText } from '~/static/icons';

export default {
    name: 'YocketProductsRedirectionsDropdown',
    props: {
        sourceModule: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            // icons
            yocketLogo,
            yocketText
        };
    },
    methods: {
        onClickHandler() {
            this.$store.dispatch('analytics/trackEvent', {
                event: analyticEvents.yocket_redirection,
                data: {
                    source: this.sourceModule
                }
            });
            this.$emit('closeDropdown');
        }
    }
};
