import Auth from './auth.js';
import TestSets from './testSets.js';
import QuestionSets from './questionSets.js';
import Answers from './answers.js';
import Solutions from './solutions.js';
import ScoreReports from './scoreReports.js';
import Users from './users.js';
import Dashboard from './dashboard.js';
import PriorityPlanner from './priorityPlanner.js';
import Premium from './premium.js';
import LearningDashboard from './learningDashboard.js';
import LiveClasses from './liveClasses.js';
import Utils from './utils.js';
import Feedbacks from './feedbacks.js';
import Articles from './articles.js';
import VocabBuilder from './vocabBuilder.js';
import Campaigns from './campaigns.js';

// FOR LOGIC REFER => plugins/api.js
export default (http) => ({
    auth: Auth(http, 'auth', 'v1'),
    testSets: TestSets(http, 'test-sets', 'v2'),
    questionSets: QuestionSets(http, 'questions', 'v2'),
    answers: Answers(http, 'answers', 'v2'),
    solutions: Solutions(http, 'solutions', 'v2'),
    scoreReports: ScoreReports(http, 'score-reports', 'v2'),
    users: Users(http, 'users', 'v1'),
    dashboard: Dashboard(http, 'dashboards', 'v2'),
    priorityPlanner: PriorityPlanner(http, 'planners', 'v2'),
    premium: Premium(http, 'prep-plus', 'v2'),
    learningDashboard: LearningDashboard(http, 'learning-dashboards', 'v2'),
    liveClasses: LiveClasses(http, 'live-classes', 'v2'),
    utils: Utils(http, 'util-apis', 'v1'),
    feedbacks: Feedbacks(http, 'feedbacks', 'v2'),
    vocabBuilder: VocabBuilder(http, 'vocab-builders', 'v2'),
    articles: Articles(http, 'articles', 'v2'),
    campaigns: Campaigns(http, 'campaigns', 'v2')
});
